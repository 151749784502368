import React, { useContext } from 'react';
import { ThemeContext } from '../ThemeProvider';
import Button from './Button';

interface AgentWebsiteReferCTAProps {
  referLink: string;
}

const AgentWebsiteReferCTA: React.FC<AgentWebsiteReferCTAProps> = ({
  referLink,
}) => {
  const colorTheme = useContext(ThemeContext);

  return (
    <div className='max-w-7xl text-gray-700 flex flex-col justify-center mx-auto md:px-12 md:py-20 p-10'>
      <p className='font-primary-medium text-2xl md:text-4.5xl text-center'>
        Let&apos;s build a shared future, together
      </p>
      <div className='flex items-center justify-center mt-4'>
        <Button
          href={referLink}
          title="I'm ready to join"
          size='lg'
          variant={colorTheme}
        />
      </div>
    </div>
  );
};

export default AgentWebsiteReferCTA;

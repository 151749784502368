import classNames from 'classnames';
import React, { useContext } from 'react';
import { RealApiAgentWebsiteResponse } from '../../types/real-api-types';
import { getCoverImageFromSet } from '../../utils/agentWebsiteUtils';
import { getThemeBgColorClassName } from '../../utils/DisplayHelpers';
import { ThemeContext } from '../ThemeProvider';
import Button from './Button';

interface AgentWebsiteReferHeaderProps {
  agentWebsiteResponse: RealApiAgentWebsiteResponse;
  referLink: string;
}

const AgentWebsiteReferHeader: React.FC<AgentWebsiteReferHeaderProps> = ({
  agentWebsiteResponse,
  referLink,
}) => {
  const colorTheme = useContext(ThemeContext);

  const photoSet = getCoverImageFromSet(
    agentWebsiteResponse?.website?.photo_sets || 'luxury'
  );

  return (
    <div className='h-screen md:h-auto md:min-h-full-6 bg-white w-full relative'>
      <picture className='absolute w-full h-full left-0 top-0 z-0'>
        <source media='(min-width: 778px)' srcSet={photoSet.large} />
        <source media='(min-width: 440px)' srcSet={photoSet.medium} />
        <img
          src={photoSet.small}
          alt='property'
          className='w-full h-full object-cover'
        />
      </picture>
      <div className='flex items-center justify-content-center h-full md:min-h-full-6 relative z-20'>
        <div className='max-w-7xl w-full mx-auto md:px-12 md:py-16 p-0'>
          <div className='max-w-xl p-6 md:p-0'>
            <div className='text-white text-4xl lg:text-6xl font-primary-medium'>
              Let&apos;s build a shared future, together
            </div>
            <div className='text-white font-primary-light text-xl lg:text-2xl mt-4 mb-10 pr-0 md:pr-28'>
              Real is a tech-powered brokerage for agents and teams, built to
              help you grow your business. Please explore our offering for
              agents below.
            </div>
            <Button
              href={referLink}
              title="I'm ready to join"
              size='lg'
              variant={colorTheme}
            />
          </div>
        </div>
      </div>
      <div className='absolute top-0 left-0 right-0 bottom-0 bg-overlay bg-opacity-70 z-10' />
      <div
        className={classNames(
          'absolute bottom-0 left-0 w-full h-1.5  border-t border-blue-800',
          getThemeBgColorClassName(colorTheme)
        )}
      />
    </div>
  );
};

export default AgentWebsiteReferHeader;

import classNames from 'classnames';
import React, { HTMLAttributeAnchorTarget } from 'react';
import { FiLoader } from 'react-icons/fi';
import { EnumMap } from '../../types';
import { ColorThemeType } from '../../types/real-api-types';
import LinkWithAnchor from '../LinkWithAnchor';

type ButtonVariantType =
  | 'primary'
  | 'secondary'
  | 'outline'
  | 'white'
  | 'outline-primary'
  | 'outline-white'
  | ColorThemeType;

type ButtonSizeType = 'sm' | 'md' | 'lg';

interface ButtonProps {
  title: string;
  type?: 'submit' | 'button' | 'reset';
  variant?: ButtonVariantType;
  href?: string;
  as?: string;
  target?: HTMLAttributeAnchorTarget;
  onClick?(): void;
  size?: ButtonSizeType;
  isLoading?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  title,
  type = 'submit',
  variant = 'primary',
  href,
  as,
  size = 'md',
  target,
  onClick,
  isLoading,
}) => {
  const ButtonVariantsClassMap: EnumMap<ButtonVariantType, string> = {
    primary: 'bg-primary text-white',
    secondary: 'bg-gray-100 border border-gray-300 text-black',
    outline:
      'border border-gray-500 text-black hover:text-white hover:bg-gray-500',
    'outline-primary': 'bg-transparent border border-primary text-primary',
    'outline-white': 'bg-transparent border border-primary text-white',
    white: 'border bg-white text-dark',
    green: 'bg-theme-green text-white',
    black: 'bg-theme-black text-white',
    blue: 'bg-theme-blue text-white',
    red: 'bg-theme-red text-white',
    yellow: 'bg-theme-yellow text-white',
    magenta: 'bg-theme-magenta text-white',
  };

  const ButtonSizeClassMap: Record<ButtonSizeType, string> = {
    sm: 'px-2 py-1',
    md: 'px-4 py-2',
    lg: 'px-6 py-3 text-lg',
  };

  const Container = href
    ? href.startsWith('http')
      ? 'a'
      : LinkWithAnchor
    : 'span';

  return (
    <Container as={as} href={href} target={target}>
      <button
        type={type}
        className={classNames(
          'rounded-sm font-primary-medium flex items-center space-x-3 whitespace-pre',
          ButtonSizeClassMap[size],
          ButtonVariantsClassMap[variant],
          {
            'cursor-wait': isLoading,
          }
        )}
        onClick={onClick}
      >
        {isLoading && (
          <span className='flex items-center'>
            <FiLoader size={18} className='animate-spin' />
          </span>
        )}
        <span>{title}</span>
      </button>
    </Container>
  );
};

export default Button;

import React, { useMemo } from 'react';
import Image from 'next/image';
import { AgentResponseAccountCountryEnum } from '../../openapi/bff';
import { BFFAgentSlugResponse } from '../../types/real-api-types';

interface AgentPlanInformationProps {
  accountCountry: AgentResponseAccountCountryEnum;
  commissionPlan: BFFAgentSlugResponse['planMembership']['commissionPlan'];
}

const AgentPlanInformation: React.FC<AgentPlanInformationProps> = ({
  accountCountry,
  commissionPlan,
}) => {
  const getAgentPlan = (): string[] => {
    return accountCountry === AgentResponseAccountCountryEnum.Canada
      ? agentPlanCA
      : agentPlanUS;
  };
  const agentPlanUS = useMemo(
    () => [
      `${100 - commissionPlan?.preCapRealCommissionSplit}/${
        commissionPlan?.preCapRealCommissionSplit
      } commission split`,
      `${commissionPlan?.annualCap} annual cap, then 100% split`,
      'No monthly fees',
      `${commissionPlan?.postCapSalesTransactionFee}/transaction fee after cap, ${commissionPlan?.postCapLeaseTransactionFee}/lease transaction fee after cap, ${commissionPlan?.beopFee} BEOP fee/transaction`,
      `${commissionPlan?.signUpFee} sign-up fee`,
      `${commissionPlan?.annualBrokerageFee} annual brokerage fee, paid from first three transactions/anniversary year`,
    ],
    [commissionPlan]
  );

  const agentPlanCA = useMemo(
    () => [
      `${100 - commissionPlan?.preCapRealCommissionSplit}/${
        commissionPlan?.preCapRealCommissionSplit
      } commission split`,
      `${commissionPlan?.annualCap} annual cap, then 100% split`,
      'No monthly fees',
      `${commissionPlan?.postCapSalesTransactionFee}/transaction fee after cap & ${commissionPlan?.postCapLeaseTransactionFee}/lease transaction fee after cap`,
      `${commissionPlan?.signUpFee} sign-up fee`,
      `${commissionPlan?.annualBrokerageFee} annual brokerage and risk management fee, paid from first three trades/anniversary year (${commissionPlan?.perTransactionBrokerageFee}/transaction)`,
    ],
    [commissionPlan]
  );

  return (
    <div className='flex lg:flex-row flex-col md:w-3/4'>
      <ul className='flex flex-col flex-1 mr-5'>
        {getAgentPlan()
          ?.slice(0, 3)
          .map((text) => (
            <li key={text.length} className='flex flex-row items-start md:mb-2'>
              <div className='pt-1'>
                <Image
                  src={require('../../assets/img/agentWebsite/check_blue_narrow.svg')}
                  alt='check'
                  layout='fixed'
                />
              </div>
              <p className='font-primary-light md:text-lg ml-2'>{text}</p>
            </li>
          ))}
      </ul>
      <ul className='flex flex-col flex-1 mr-5'>
        {getAgentPlan()
          ?.slice(3)
          .map((text) => (
            <li key={text.length} className='flex flex-row items-start md:mb-2'>
              <div className='pt-1'>
                <Image
                  src={require('../../assets/img/agentWebsite/check_blue_narrow.svg')}
                  alt='check'
                  layout='fixed'
                />
              </div>
              <p className='font-primary-light md:text-lg ml-2'>{text}</p>
            </li>
          ))}
      </ul>
    </div>
  );
};

export default AgentPlanInformation;

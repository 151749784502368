import React from 'react';

interface RevenueShareTierTableProps {
  title?: string;
  sponsorData: {
    tier: string;
    bonus: string;
    noOfAgents: number;
    maxAgenPerYear: string;
  }[];
}

const RevenueShareTierTable: React.FC<RevenueShareTierTableProps> = ({
  title = 'Single Sponsor',
  sponsorData,
}) => {
  return (
    <div className='max-w-3xl border rounded-sm'>
      <div className='border-b text-center w-full text-3xl p-3 md:p-9 '>
        {title}
      </div>
      <table className='table-auto mx-auto'>
        <thead className='font-primary-light '>
          <tr className='text-sm leading-5'>
            <th className='border-r p-2 md:p-5 w-28'> </th>
            <th className='text-xs md:text-xl p-2 md:py-5 md:pl-3 md:pr-3 uppercase'>
              <p>{`${title} REFERRAL BONUS GCI%`}</p>
            </th>
            <th className='text-xs md:text-xl border-r p-2 md:py-5 md:pl-3 md:pr-3'>
              <p>NUMBER OF TIER 1</p>
              <p>AGENTS REQUIRED TO UNLOCK TIER</p>
            </th>
            <th className='text-xs md:text-xl p-2 md:p-5 w-60'>
              <p>MAX/AGENT PER YEAR</p>
            </th>
          </tr>
        </thead>
        <tbody className='text-xs md:text-xl font-primary-light'>
          {sponsorData.map((data) => {
            return (
              <tr
                key={data.maxAgenPerYear}
                className='border-t border-sky-300 text-center'
              >
                <td className='border-r p-2 md:p-5'>{data.tier}</td>
                <td className=' p-2  md:p-5'>{data.bonus}</td>
                <td className='border-r p-2 md:p-5'>{`${data.noOfAgents} + producing agent`}</td>
                <td className='p-2 md:p-5'>{data.maxAgenPerYear}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default RevenueShareTierTable;

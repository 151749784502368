import React, { useMemo } from 'react';
import Image from 'next/image';
import { AgentResponseAccountCountryEnum } from '../../openapi/bff';
import { BFFAgentSlugResponse } from '../../types/real-api-types';

interface TeamsPlanInformationProps {
  accountCountry: AgentResponseAccountCountryEnum;
  commissionPlan: BFFAgentSlugResponse['planMembership']['commissionPlan'];
}

const TeamsPlanInformation: React.FC<TeamsPlanInformationProps> = ({
  accountCountry,
  commissionPlan,
}) => {
  const getTeamPlan = (): string[] => {
    return accountCountry === AgentResponseAccountCountryEnum.Canada
      ? teamPlanCA
      : teamPlanUS;
  };

  const teamPlanUS = useMemo(
    () => [
      `${100 - commissionPlan?.preCapRealCommissionSplit}/${
        commissionPlan?.preCapRealCommissionSplit
      } commission split`,
      `${commissionPlan?.normalTeamLeadAnnualCap} team leader annual cap, ${commissionPlan?.normalTeamMemberAnnualCap} team member annual cap, then 100% split`,
      'No monthly fees',
      'Team leader qualifications of $5M or 25 transactions in prior 12 months',
      `${commissionPlan?.signUpFee} sign-up fee`,
      `${commissionPlan?.annualBrokerageFee} annual brokerage fee, paid from first three transactions/anniversary year`,
      `Minimum 10% team member split to leader`,
    ],
    [commissionPlan]
  );

  const teamPlanCA = useMemo(
    () => [
      `${100 - commissionPlan?.preCapRealCommissionSplit}/${
        commissionPlan?.preCapRealCommissionSplit
      } commission split`,
      `${commissionPlan?.normalTeamLeadAnnualCap} team leader annual cap, ${commissionPlan?.normalTeamMemberAnnualCap} team member annual cap, then 100% split`,
      'No monthly fees',
      'Team leader qualifications of $5M CAD or 25 transactions in prior 12 months',
      `${commissionPlan?.signUpFee} sign-up fee`,
      `${commissionPlan?.annualBrokerageFee} annual brokerage and risk management fee, paid from first three trades/anniversary year (${commissionPlan?.perTransactionBrokerageFee}/transaction)`,
      `Minimum 10% team member split to leader`,
    ],
    [commissionPlan]
  );
  return (
    <div className='flex lg:flex-row flex-col md:w-3/4'>
      <ul className='flex flex-col flex-1 mr-5'>
        {getTeamPlan()
          ?.slice(0, 3)
          .map((text) => (
            <li key={text.length} className='flex flex-row items-start md:mb-2'>
              <div className='pt-1'>
                <Image
                  src={require('../../assets/img/agentWebsite/check_blue_narrow.svg')}
                  alt='check'
                  layout='fixed'
                />
              </div>
              <p className='font-primary-light md:text-lg ml-2'>{text}</p>
            </li>
          ))}
      </ul>
      <ul className='flex flex-col flex-1 mr-5'>
        {getTeamPlan()
          ?.slice(3)
          .map((text) => (
            <li key={text.length} className='flex flex-row items-start md:mb-2'>
              <div className='pt-1'>
                <Image
                  src={require('../../assets/img/agentWebsite/check_blue_narrow.svg')}
                  alt='check'
                  layout='fixed'
                />
              </div>
              <p className='font-primary-light md:text-lg ml-2'>{text}</p>
            </li>
          ))}
      </ul>
    </div>
  );
};

export default TeamsPlanInformation;

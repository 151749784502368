// eslint-disable-next-line import/no-nodejs-modules
import { ParsedUrlQuery } from 'querystring';
import { GetStaticPaths, GetStaticProps, NextPage } from 'next';
import Image from 'next/image';
import React, { useMemo } from 'react';
import { useRouter } from 'next/router';
import AgentLayoutRedesigned from '../../components/agent/AgentLayoutRedesigned';
import AgentWebsiteReferCTA from '../../components/agent/AgentWebsiteReferCTA';
import AgentWebsiteReferHeader from '../../components/agent/AgentWebsiteReferHeader';
import RevenueShareTierTable from '../../components/RevenueShare/RevenueShareTierTable';
import Seo from '../../components/Seo';
import ThemeProvider from '../../components/ThemeProvider';
import ContentfulService from '../../services/ContentfulService';
import { CommonPageDataProps } from '../../types';
import {
  BFFAgentSlugResponse,
  RealApiAgentWebsiteResponse,
} from '../../types/real-api-types';
import {
  getAgentPlans,
  getAgentWebsiteApiResponse,
} from '../../utils/agentWebsiteUtils';
import env from '../../utils/env';
import { OfficesResponse } from '../../openapi/bff';
import BFFApiService from '../../services/BFFApiService';
import ErrorService from '../../services/ErrorService';
import AgentPlanInformation from '../../components/refer/AgentPlanInformation';
import TeamsPlanInformation from '../../components/refer/TeamsPlanInformation';
import { getAgentSlug } from '../../utils/AgentUtils';

interface AgentWebsiteIndexPageProps extends CommonPageDataProps {
  agentWebsiteResponse: RealApiAgentWebsiteResponse;
  allOffices: OfficesResponse;
  bffAgentSlugResponse: BFFAgentSlugResponse;
}

interface PageParams extends ParsedUrlQuery {
  agentSlug: string;
}

export const coSponsorData = [
  {
    tier: 'Tier 1',
    bonus: '2.25%',
    noOfAgents: 1,
    maxAgenPerYear: '$1800',
  },
  {
    tier: 'Tier 2',
    bonus: '1.80%',
    noOfAgents: 10,
    maxAgenPerYear: '$1440',
  },
  {
    tier: 'Tier 3',
    bonus: '1.35%',
    noOfAgents: 15,
    maxAgenPerYear: '$1080',
  },
  {
    tier: 'Tier 4',
    bonus: '0.90%',
    noOfAgents: 20,
    maxAgenPerYear: '$720',
  },
  {
    tier: 'Tier 5',
    bonus: '0.45%',
    noOfAgents: 25,
    maxAgenPerYear: '$360',
  },
];

const AgentWebsiteReferPage: NextPage<AgentWebsiteIndexPageProps> = ({
  agentWebsiteResponse,
  countriesWithStates,
  footerContent,
  allOffices,
  bffAgentSlugResponse,
}) => {
  const router = useRouter();
  const { agentSlug } = router.query;
  const sponsorName =
    agentWebsiteResponse?.agent?.name || bffAgentSlugResponse?.fullName;
  const referLink = `${
    env.NEXT_PUBLIC_BOLT_DOMAIN
  }/register?sponsorCode=${encodeURIComponent(
    getAgentSlug(agentWebsiteResponse, agentSlug as string)
  )}&sponsorName=${encodeURIComponent(sponsorName)}`;

  const extraRevenueShare = useMemo(() => {
    return (bffAgentSlugResponse?.planMembership?.revenueSharePlan?.tiers || [])
      ?.sort((tier1, tier2) => (tier1?.tier > tier2?.tier ? 1 : -1))
      ?.map((tier) => {
        return {
          tier: `Tier ${tier?.tier}`,
          bonus: `${tier?.commissionPercent}% up to ANNUAL CAP`,
          noOfAgents: tier?.numberOfTierOneAgentsToUnlockThisTier,
          maxAgenPerYear: tier?.maxAnnualAmountPerAgent,
        };
      });
  }, [bffAgentSlugResponse?.planMembership?.revenueSharePlan?.tiers]);

  return (
    <ThemeProvider theme={agentWebsiteResponse?.website?.color_theme || 'blue'}>
      <AgentLayoutRedesigned
        agentWebsiteResponse={agentWebsiteResponse}
        countriesWithStates={countriesWithStates}
        footerContent={footerContent}
        showFooterCTA
        agentSlug={agentSlug as string}
        allOffices={allOffices}
        bffAgentSlugResponse={bffAgentSlugResponse}
      >
        <Seo
          title={sponsorName}
          description={agentWebsiteResponse?.website?.main_title || ''}
          imageUrl={agentWebsiteResponse?.agent?.profile_image?.main || ''}
        />
        <AgentWebsiteReferHeader
          agentWebsiteResponse={agentWebsiteResponse}
          referLink={referLink}
        />
        <div className='max-w-7xl text-gray-700 flex flex-col justify-center mx-auto md:px-12 md:py-16 p-6'>
          <div>
            <div className='flex flex-row items-center mb-7'>
              <div className='md:w-8 w-6'>
                <Image
                  src={require('../../assets/img/agentWebsite/agent_plan.png')}
                  alt='agent-plan'
                  layout='responsive'
                />
              </div>
              <p className=' md:text-2xl text-xl font-primary-medium ml-3'>
                Agent plan
              </p>
            </div>
            <p className='font-primary-medium font-medium text-5xl md:text-4.5xl '>
              Build your business
            </p>
            <p className='text-lg md:text-2xl font-primary-light my-4'>
              Build your real estate business with Real&apos;s great splits
              &amp; tech tools — no office required.
            </p>
          </div>
          <div className='border border-gray-300 flex md:flex-row flex-col justify-between my-5 p-8'>
            <div className='flex flex-col items-start w-1/4'>
              <p className='font-primary-medium md:text-2xl text-lg my-3'>
                Agents
              </p>
            </div>

            <AgentPlanInformation
              accountCountry={
                bffAgentSlugResponse?.planMembership?.commissionPlan?.country
              }
              commissionPlan={
                bffAgentSlugResponse?.planMembership?.commissionPlan
              }
            />
          </div>
          <div className='border border-gray-300 flex md:flex-row flex-col justify-between mt-5 mb-10 md:p-7 p-5'>
            <div className='flex flex-col items-start w-1/4'>
              <p className='font-primary-medium md:text-2xl text-lg my-3'>
                Teams
              </p>
            </div>
            <TeamsPlanInformation
              accountCountry={
                bffAgentSlugResponse?.planMembership?.commissionPlan?.country
              }
              commissionPlan={
                bffAgentSlugResponse?.planMembership?.commissionPlan
              }
            />
          </div>
          <p className='font-primary-light-italic text-base text-center'>
            {`* 100% commission, ${bffAgentSlugResponse?.planMembership?.commissionPlan?.personalDealFee}/ transaction fee on up to ${bffAgentSlugResponse?.planMembership?.commissionPlan?.allowedPersonalDeals} personal sales
            deals per year`}
          </p>
        </div>
        <div className='bg-gray-50'>
          <div className='max-w-7xl text-gray-700 flex flex-col justify-center mx-auto md:px-12 md:py-16 p-6'>
            <div className='px-3 space-y-5'>
              <div className='flex flex-row items-center mb-7'>
                <div className='md:w-8 w-6'>
                  <Image
                    src={require('../../assets/img/agentWebsite/revenue_share.png')}
                    alt='revenue share'
                    width={35}
                    height={35}
                    layout='fixed'
                  />
                </div>
                <p className=' md:text-2xl text-xl font-primary-medium ml-3'>
                  Revenue share
                </p>
              </div>
              <p className='text-3xl md:text-4.5xl font-primary-medium'>
                Earn extra revenue
              </p>
              <div className='leading-tight'>
                <p className='text-xl leading-tight md:text-2xl font-primary-light max-w-4xl'>
                  Earn 5% revenue share up to $4000 per capping agent from
                  Real&apos;s portion of the split. Unlock additional tiers as
                  your network grows.
                </p>
              </div>
              <div className='mt-5 flex-col space-y-14'>
                <div className='bg-white '>
                  <RevenueShareTierTable sponsorData={extraRevenueShare} />
                </div>
                <div className='bg-white'>
                  <RevenueShareTierTable
                    title='Co-Sponsor'
                    sponsorData={coSponsorData}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='max-w-7xl text-gray-700 flex flex-col justify-center mx-auto md:px-12 md:pt-16'>
          <div className='flex flex-col md:flex-row items-center md:space-x-10'>
            <div className='max-w-xl'>
              <div className='p-8 bg-sky-50'>
                <div className='bg-white'>
                  <ul className='p-8 border border-blue-300'>
                    <li className='flex flex-row items-start md:mb-2'>
                      <div className='pt-1'>
                        <Image
                          src={require('../../assets/img/agentWebsite/check_blue_narrow.svg')}
                          alt='check'
                          layout='fixed'
                        />
                      </div>
                      <p className='font-primary-light md:text-lg ml-2'>
                        <span className='font-primary-medium'>
                          Purchase stock at a discount with commissions:
                        </span>{' '}
                        Agents can have 5% of net commissions paid out in stock.
                      </p>
                    </li>
                    <li className='flex flex-row items-start md:mb-2'>
                      <div className='pt-1'>
                        <Image
                          src={require('../../assets/img/agentWebsite/check_blue_narrow.svg')}
                          alt='check'
                          layout='fixed'
                        />
                      </div>
                      <p className='font-primary-light md:text-lg ml-2'>
                        <span className='font-primary-medium'>
                          Earn stock by capping and attracting agents:
                        </span>{' '}
                        When an agent caps, the agent will be granted shares in
                        stock.
                      </p>
                    </li>
                    <li className='flex flex-row items-start md:mb-2'>
                      <div className='pt-1'>
                        <Image
                          src={require('../../assets/img/agentWebsite/check_blue_narrow.svg')}
                          alt='check'
                          layout='fixed'
                        />
                      </div>
                      <p className='font-primary-light md:text-lg ml-2'>
                        <span className='font-primary-medium'>
                          Elite Agent stock awards:
                        </span>{' '}
                        Agents who reach production benchmarks can earn up to
                        $24,000 in stock.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className='grow'>
              <div className='w-full max-w-xl md:p-8 px-6 py-10 space-y-4'>
                <div className='flex items-center space-x-2'>
                  <Image
                    src={require('../../assets/img/agentWebsite/equity.png')}
                    alt='equity'
                    width={50}
                    height={35}
                    layout='fixed'
                  />
                  <div className='text-2xl'>Equity</div>
                </div>
                <p className='text-2xl md:text-4xl font-primary-medium pt-2'>
                  Own a piece of your company
                </p>
                <p className='text-lg md:text-2xl leading-6 font-primary-light'>
                  Real is publicly trading as The Real Brokerage Inc. (NASDAQ:
                  REAX) (TSX: REAX). Agents can earn a piece of the company
                  through three stock plans.
                </p>
              </div>
            </div>
          </div>
        </div>
        <AgentWebsiteReferCTA referLink={referLink} />
      </AgentLayoutRedesigned>
    </ThemeProvider>
  );
};

export const getStaticPaths: GetStaticPaths = async () => {
  return { paths: [], fallback: 'blocking' };
};

export const getStaticProps: GetStaticProps<
  AgentWebsiteIndexPageProps,
  PageParams
> = async (ctx) => {
  const contentfulService = new ContentfulService();
  const bffApiService = new BFFApiService();

  const [
    agentWebsiteResponse,
    countriesWithStates,
    footerContent,
    allOffices,
    bffAgentSlugResponse,
  ] = await Promise.all([
    getAgentWebsiteApiResponse(ctx.params.agentSlug),
    contentfulService.getCountriesWithStates(),
    contentfulService.getFooterContent(),
    bffApiService.fetchAllOfficialOffices(),
    getAgentPlans(ctx.params.agentSlug),
  ]);

  if (!bffAgentSlugResponse) {
    return { notFound: true, revalidate: 1 };
  }

  if (!countriesWithStates || !footerContent) {
    const error = new Error('Unable to load refer page');
    ErrorService.notify('Unable to load agent website refer page!', error, {
      apiCalls: {
        countriesWithStates: !!countriesWithStates,
        footerContent: !!footerContent,
      },
    });

    throw error;
  }

  return {
    props: {
      agentWebsiteResponse: agentWebsiteResponse || null,
      countriesWithStates,
      footerContent,
      allOffices,
      bffAgentSlugResponse,
    },
    revalidate: 1,
  };
};

export default AgentWebsiteReferPage;
